import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '公司介绍', keywords: '', description: '' }
    },
    {
      path: '/news/:type(\\d+)?',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/news/detail/:id(\\d+)',
      name: 'news',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/products/:pid(\\d+)?',
      name: 'products',
      component: () => import('./views/Products.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/products/detail/:id(\\d+)',
      name: 'products',
      component: () => import('./views/ProductDetail.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系我们', keywords: '', description: '' }
    },
    {
      path: '/message',
      name: 'message',
      component: () => import('./views/Message.vue'),
      meta: { title: '在线留言', keywords: '', description: '' }
    },
    {
      path: '/partner/:id(\\d+)?',
      name: 'partner',
      component: () => import('./views/Partner.vue'),
      meta: { title: '合作伙伴', keywords: '', description: '' }
    },
    {
      path: '/tech/:id(\\d+)',
      name: 'tech',
      component: () => import('./views/Tech.vue'),
      meta: { title: '应用技术', keywords: '', description: '' }
    }
  ]
})
