<template>
  <v-app>
    <div style="height:108px;box-shadow: 0 6px 8px rgba(20,19,19,0.04);">
    <v-navigation-drawer
      right
      v-model="drawer"
      app
      temporary
    >
      <v-list dense subheader>
        <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title>首页</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/about">
          <v-list-item-content>
            <v-list-item-title>公司介绍</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/news">
          <v-list-item-content>
            <v-list-item-title>公司动态</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>产品系统</v-subheader>
        <template v-if="info.product">
          <v-list-item v-for="(cat,pk) in info.product.category.cn" :href="'/products/' + cat.id" v-bind:key="pk">
            <v-list-item-content>
              <v-list-item-title>{{ cat.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-subheader>合作伙伴</v-subheader>
        <v-list-item v-for="(link,lk) in info.link" :href="'/partner/' + link.id" v-bind:key="lk">
            <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>联系我们</v-subheader>
        <v-list-item to="/contact">
          <v-list-item-content>
            <v-list-item-title>联系方式</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/message">
          <v-list-item-content>
            <v-list-item-title>在线留言</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat absolute class="app-bar" height="108" max-width="1264">
      <v-toolbar-title class="headline" style="display: flex;align-items: center;">
        <img :src="info.logo" style="height:103px;"/>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn id="menu_home" href="/" class="ml-0 hidden-sm-and-down white text-none ctk-menu">首页</v-btn>
        <v-btn id="menu_about" href="/about" class="ml-0 hidden-sm-and-down white text-none ctk-menu">公司介绍</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn id="menu_product" v-on="on" class="white text-none hidden-sm-and-down">产品系统</v-btn>
          </template>
          <v-list v-if="info.product">
            <v-list-item v-for="(cat, pkk) in info.product.category.cn"  :href="'/products/' + cat.id" v-bind:key="pkk">
              <v-list-item-title>{{cat.name}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn id="menu_partner" v-on="on" class="white text-none hidden-sm-and-down">合作伙伴</v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(link, lkk) in info.link"  :href="'/partner/' + link.id" v-bind:key="lkk">
              <v-list-item-title>{{link.title}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn id="menu_news" href="/news" class="ml-0 hidden-sm-and-down white text-none ctk-menu">公司动态</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn id="menu_contact" v-on="on" class="white text-none hidden-sm-and-down" >联系我们</v-btn>
          </template>
          <v-list>
            <v-list-item href="/contact">
              <v-list-item-title>联系方式</v-list-item-title>
            </v-list-item>
            <v-list-item href="/message">
              <v-list-item-title>在线留言</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>
    </div>
    <v-content>
      <router-view></router-view>
    </v-content>
    <Footer/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer')
  },
  data: () => ({
    afterGetData: false,
    drawer: null,
    languages: ['简体中文', 'English']
  }),
  mounted () {
    this.$store.dispatch('loadInfo')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        // this.info.logo = val.logo
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector('link[rel*=\'icon\']').setAttribute('href', val.favicon)
      },
      deep: true
    },
    '$route' (to, from) {
      this.title(this.$route)
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    /**
     * 切换语言
     * @param index
     * @returns {boolean}
     */
    changeLanguage (index) {
      let currentLanguage
      if (index) {
        currentLanguage = 'en'
      } else {
        currentLanguage = 'cn'
      }
      if (currentLanguage === 'en') {
        window.location.href = 'https://www.renolit.com/en'
      }
      /*
      if (localStorage.getItem('locale') === currentLanguage) {
        return false
      }
      localStorage.setItem('locale', currentLanguage)
      this.$i18n.locale = currentLanguage
      window.location.reload()
      */
    },
    /**
     * 路由切换设置title
     * @param to
     */
    title (to) {
      // document.title = to.meta.title + '_' + this.$store.getters.companyInfo.name
    }
  }
}
</script>
<style lang="scss">
  @import 'sass/variables.scss';
  .v-application {
    font-family: $body-font-family !important;
  }
</style>
<style>
  main {padding: 0 !important;}
  a {
    text-decoration: none;
  }

  .theme--light {
    background: #fff !important;
  }

  .ctk-footer ul {
    list-style-type: none;
    padding-left: 0 !important;
  }

  .ctk-footer a {
    font-size: 14px;
    color: #909399 !important;
  }

  .ctk-footer .ctk-footer-title {
    color: #999;
    font-size: 18px;
  }

  .ctk-footer .ctk-footer-name {
    font-size: 20px;
  }
  .ctk-footer .ctk-sub-name {
    font-size: 16px;
    color: #909399 !important;
  }

  .ctk-footer span {
    font-size: 14px !important;
  }

  .ctk-footer .ctk-footer-link li {
    margin-right: 10px;
    font-size: 16px;
    color: #909399 !important;
  }

  .ctk-footer .ctk-footer-copyright {
    margin-top: 40px;
    color: #999;
  }

  .ctk-video-div {
    margin-top: 100px;
  }

  .ctk-video video {
    width: 100%;
    border: none;
    margin: 0;
  }

  .ctk-div {
    padding-top: 60px !important;
  }

  .ctk-div-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
  }

  .app-bar {
    margin: auto;
  }

  .app-bar .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
    will-change: box-shadow;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .app-bar .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
    opacity: 1;
  }

  .app-bar .v-btn--active:before, .v-btn:focus:before {
    background: none;
    /*border-bottom: 2px solid #076DB1;*/
  }

  .app-bar .v-btn--active > .v-btn__content {
    color: #076DB1;
  }

  .ctk-home-tab .v-tab--active {
    background: #076DB1 !important;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 0.2em;
  }

  .ctk-home-tab .v-tabs-slider {
    background: none !important;
  }

  .smallProduct {
    padding: 60px !important;
  }

  .ctk-home-tab .v-item-group {
    margin-bottom: 2em !important;
  }

  .ctk-more-btn-div {
    margin-bottom: 2em !important;
  }

  .ctk-news-publish {
    background: #CCC;
  }

  .ctk-news-publish:hover {
    background: #076DB1;
  }

  .ctk-news-publish-enter {
    background: #076DB1;
  }

  .ctk-div-news {
    background: #F8F8F8 !important;
  }

  .ctk-div-news .v-tabs-bar {
    background: #F8F8F8 !important;
  }

  .ctk-about-submenu {
    background: rgba(255, 255, 255, 0) !important;
    border-radius: 0.4em !important;
  }

  .ctk-about-submenu .v-tabs {
    background: rgba(255, 255, 255, 0) !important;
    border-radius: 0.4em !important;
  }

  .ctk-about-submenu .v-tabs-bar {
    background: rgba(255, 255, 255, 0.7) !important;
    cursor: initial;
  }

  .ctk-about-submenu .v-tab--active {
    background: rgba(255, 255, 255, 0) !important;
    color: #076DB1 !important;
    font-size: 14px !important;
    border-radius: 0.2em;
  }

  .ctk-about-submenu .v-tabs-slider {
    background: none !important;
  }

  /*
  .ctk-timeline .v-timeline-item {padding-bottom: 80px;}
  .ctk-timeline .v-timeline-item__dot {border-radius: 0 !important; width:100px; height:100px;color: #fff; font-size:32px;}
  .ctk-timeline .v-timeline-item__inner-dot {border-radius: 0 !important;}
  .ctk-timeline .v-timeline-item__body {margin-left: 40px;}
  .ctk-timeline:before { background: rgba(0,128,230, 0.3) !important; width: 8px !important; }
  */
  .v-pagination {
    background: #FFF !important;
  }

  .v-pagination button {
    background: #FFF !important;
  }

  .v-pagination button, .v-pagination button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }

  .v-pagination__navigation .mdi-chevron-left, .v-pagination__navigation .mdi-chevron-right {
    background: #FFF !important;
  }

  .ctk-news-title {
    color: #000;
  }

  .ctk-news-profile {
    margin-top: 20px;
    margin-bottom: 60px;
    color: #333;
  }

  .ctk-news-date {
    position: absolute;
    bottom: 20px;
    color: #333;
  }

  .ctk-customer-logo {
    max-height: 100px;
    box-shadow: 2px 2px 5px #888;
    cursor: pointer;
    transition: all 0.25s ease;
    z-index: 0;
  }

  .ctk-customer-logo:hover {
    box-shadow: none;
    border: 1px solid #ccc;
    transform: scale(2);
    z-index: 1;
  }

  .ctk-case-category-img {
    max-height: 200px;
  }

  /*.ctk-case-category-card  {background:#f8f8f8 !important;}*/
  .ctk-case-category-hover {
    color: #fff !important;
  }

  .ctk-case-category-card:hover {
    /*background:rgba(1,92,168,0.7) !important;*/
  }

  .v-overlay__content {
    width: 100%;
    overflow: hidden;
  }

  /*.ctk-select.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
  }
  .ctk-select.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }*/
  .ctk-qa {
    background: #f8f8f8 !important;
  }

  .ctk-qa .v-expansion-panel:before {
    box-shadow: none !important;
  }

  .ctk-qa .v-expansion-panel-header {
    border: none !important;
  }

  .v-application .elevation-2 {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
  }
  .ctk-jx-category .v-card {box-shadow: none !important;}
  .v-application .ctk-jx-category .elevation-2 {box-shadow: none !important;}
  .ctk-case-category-card {
    border: 1px solid #f6f6f6 !important;
    min-height:200px;
    margin: 10px;
    background-color: #015CA8 !important;
    display: inline-block;
    background-size: cover;
    position: relative;
    cursor: pointer;
    transition: all 0.4s ease-out;
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
    overflow: hidden;
    color: white;
    font-family: 'Roboto';
  }

  .ctk-case-category-card .ctk-case-category-img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: all 0.4s ease-out;
  }

  .ctk-case-category-card .ctk-cat-text {
    z-index: 99;
    padding: 30px;
    height: calc(100% - 60px);
    height: calc(100% - 60px);
  }

  .ctk-case-category-card h1 {
    background: linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 100%);
    text-align: left;
    width: 100%;
    position: absolute;
    left: 0;
    padding-left: 16px;
    padding-bottom: 16px;
    bottom: 0px;
    fon-size:20px;
    font-weight: 400;
    color: #303133;
    margin: 0;
    /*text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);*/
  }
  .ctk-case-category-card:hover {
    outline: none !important;
    background-color:#99aeff;
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
    /*transform: scale(1.05);*/
  }

  .ctk-case-category-card:hover .ctk-case-category-img {
    opacity: 0.3;
  }
  .ctk-case-category-card:hover .ctk-cat-text h1 {
    color: #fff !important;
    background: none;
  }
  .theme--light.v-list-item:hover {
    background: #015CA8 !important;
  }
  .theme--light.v-list-item:hover .v-list-item__title {
    color: #fff;
  }
  .app-bar .v-btn:hover{
    /*background: #015CA8 !important;*/
  }
  .app-bar .v-btn__content {
    text-align: center;
    font-size: 18px !important;
  }
  .app-bar .v-btn:hover .v-btn__content {
    /*color: #fff;*/
  }
  .v-list-item__title, .v-list-item__subtitle {
    text-align: center;
  }
  .v-toolbar__items .v-btn:before {
    background: none;
  }
  .v-menu__content {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
    opacity: 0.9;
  }
  .v-menu__content .v-list {
    padding:0 !important;
  }
  h1 {
    font-size: 20px !important;
  }
  .v-list--dense .v-subheader {
    padding: 0 60px;
    color: #333 !important;
    font-size: 1em;
  }
</style>
